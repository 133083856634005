import { Provider } from "react-redux";
import "./App.css";
import RoutingGenai from "./routes";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import appStore from "./store/Appstore";
import Charging from "./Charging";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const clientId =
    "1023717850676-ubcj87dn68ohoab73k1b714voktqhjqq.apps.googleusercontent.com";

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Provider store={appStore}>
        <div className="App">
          <RoutingGenai />
        </div>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
